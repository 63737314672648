import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { useModal } from '../../hooks/useModal';

// Componentes
import DependenciaReporte from './DependenciaRepote';
import Rotulo from '../Rotulo/Rotulo';
import Pie from '../Pie/Pie';
import ModalComponent from '../ModalComponent/ModalComponent';

// Acciones
import { buscar_cabeceras, buscar_dependencias_hijas_con_padre, restaurarHijas } from '../../redux/action-types/dependenciaActions';
import { descargar_reporte_saf } from '../../redux/action-types/marcacionesActions';

// MUI Componentes
import { 
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,  
  Grid,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Typography,
  useMediaQuery
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { verificarPermiso } from '../../utils/verificarPermiso';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 140,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'primary.light',
    color: 'white',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const BuscarDependenciaReporte = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const permisos = window.localStorage.getItem("permisos");

  const { cabeceras, hijas, padre } = useSelector(state => state.dependencia);
  const [isOpenModal, openModal, closeModal] = useModal();

  const currentMonth = new Date().getMonth(); // getMonth() devuelve un valor de 0 a 11, por lo que se suma 1
  const currentYear = new Date().getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [ isProcessing, setIsProcessing ] = useState(false);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const months = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' },
  ];

  const years = [];
  for (let year = currentYear; year >= 2016; year--) {
    years.push(year);
  }

  const matches = useMediaQuery('(min-width:500px)');

  const [ultimaSeleccionada, setUltimaSeleccionada] = useState(null);
  
  const handleDownload = (formato) => {
    setIsProcessing(true);
    const data = {mes: selectedMonth, año:selectedYear, formato: formato}
    dispatch(descargar_reporte_saf(data)).then(() => {
      setIsProcessing(false);
      handleClose();
      closeModal();
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSAF = () => {
    openModal();
  };

  const handleCancel = () => {
    closeModal();
  }

  const handleSeleccion = (index) => {
    setUltimaSeleccionada(index);
  };

  const handleOficina = (id) => {
    dispatch(buscar_dependencias_hijas_con_padre(id));
  }

  const handleDependencia = (id) => {
    window.sessionStorage.setItem("urlAnterior", `/reportes-generales`);
    navigate(`/reportes-generales/${id}/completo`);      
        
    //;
  };
  
  useEffect(() => {
    dispatch(buscar_cabeceras());
    dispatch(restaurarHijas());
  }, [dispatch]);
  
  return (
    <>
      <Rotulo titulo={"Seleccionar Oficina"} />
      {/* Contenedor del componente */}
      <Container maxWidth="full" 
        sx={{ 
          width: '100%',
          height: '100%', 
          display:"flex",
          justifyContent: 'center',
          marginBottom: '5em',
          marginTop: '3em',
        }}
      >
      <Grid component="main" container spacing={4}
          
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifySelf: 'center',
            alignItems: 'center',        
            margin: {xs:"auto", sm:0},    
          }}
        >
          <Grid  xs={12} sm={6} md={6} lg={6} spacing={4}
            sx={{
              width: '50%',
              height: {xs:'auto', sm:"100%"},
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              order: { xs: 1, sm: 1 },
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '4em',
              }}
            >
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '1em',
              }}>
                <ApartmentIcon></ApartmentIcon>
                <Typography component={"h3"} fontSize={'20px'} marginLeft={"5px"} fontWeight={"bold"}>
                    Dependencias:
                </Typography>
              </Box>
              {/* Renderizado de resultados */}
              {
                cabeceras && cabeceras.map((dependencia, index) => {
                  if (dependencia.name === "UNLP"){
                    return null
                  }
                  return (
                    <DependenciaReporte 
                      key={dependencia.id}
                      id={dependencia.id}
                      nombre={dependencia.name}
                      oficina={false}
                      reporte={false}
                      onClick={() => handleSeleccion(index)}
                      seleccionada={ultimaSeleccionada === index}                      
                    />
                  )
                })
              }
              {verificarPermiso(permisos, "usuario_saf") ?
              <>
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '1em',
                paddingTop: '1em',
              }}>
                <AssignmentIcon></AssignmentIcon>
                <Typography component={"h3"} fontSize={'20px'} marginLeft={"5px"} fontWeight={"bold"}>
                    Reportes especiales:
                </Typography>
              </Box>
              <DependenciaReporte 
                  key={"Reporte SAF"}
                  id={"Reporte SAF"}
                  nombre={"Reporte SAF"}
                  oficina={false}
                  reporte={false}
                  onClick={() => handleSAF()}
                  saf={true}                                        
                />
              </>
              :
              null}
              
            </Box>
          </Grid>
          {/* Search Input */}
          <Grid xs={12} sm={6} md={6} lg={6} spacing={4}
            sx={{
              width: '50%',
              height: {xs:'auto', sm:"100%"},
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: {xs:"auto", sm:0},
              order: { xs: 2, sm: 2 },
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '4em',
              }}
            >
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '1em',
              }}>
                <HomeWorkIcon></HomeWorkIcon>
                <Typography component={"h3"} fontSize={'20px'} marginLeft={"5px"} fontWeight={"bold"}>
                    {padre ? `Oficinas de ${padre.name}` : "Oficinas"}
                </Typography>
              </Box>
              {/* <SearchInput handleChange={handleChange} handleClick={handleClick} placeholder={"Ingrese el lugar"} /> */}
            
            {/* Renderizado de resultados */}
            {padre ?
            <HtmlTooltip placement="left" arrow title={
              <React.Fragment>
                <Box>
                <Button onClick={() => handleDependencia(padre.id)}
              sx={{
                color: "white",
                fontWeight: "bold",
                justifyContent: "center",
                alignContent: "center"
              }}>
                Reporte con suboficinas
                </Button>
                </Box>
              </React.Fragment>}
              >
            <div width="auto">
            <DependenciaReporte 
              key={padre.id}
              id={padre.id}
              nombre={padre.name}
              oficina={false} 
              reporte={true}
            />
            </div>
            </HtmlTooltip>
              
            :
            <Typography>Seleccione una dependencia para ver sus oficinas</Typography>}
            {hijas ?
                hijas && hijas.map(dependencia => {    
                  if (!matches) {
                  return(<DependenciaReporte 
                    key={dependencia.id}
                    id={dependencia.id}
                    nombre={dependencia.name}
                    oficina={true} 
                    reporte={true}
                  />)
                  }
                  else{
                    return (
                      <HtmlTooltip placement="left" arrow title={
                        <React.Fragment>
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}>
                        <Button onClick={() => handleOficina(dependencia.id)}
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          justifyContent: "center",
                          alignContent: "center"
                        }}>
                          Buscar suboficinas
                          </Button>
                          <Divider></Divider>
                          <Button onClick={() => handleDependencia(dependencia.id)}
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          justifyContent: "center",
                          alignContent: "center"
                        }}>
                          Reporte con suboficinas
                          </Button>
                          </Box>
                        </React.Fragment>}
                        >
                      <div width="auto">
                      <DependenciaReporte 
                        key={dependencia.id}
                        id={dependencia.id}
                        nombre={dependencia.name}
                        oficina={true} 
                        reporte={true}
                      />
                      </div>
                      </HtmlTooltip>
                    )
                  }                  
                })
              :
              null
              }
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* Modal */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        {isProcessing ? 
        <Grid container 
        sx={{
          display: "flex",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
            Generando...
        </Typography>
        <CircularProgress sx={{ marginLeft: "3em" }} size={30}/>
      </Grid>
        :        
        <Grid id={"editar_vacacion"} container >
        <Typography sx={{marginBottom: "0.5em", width:"100%"}} id="modal-modal-title" variant="h6" component="h2">
          Reporte de la SAF
        </Typography>

        <Box sx={{marginBottom: "0.5em", width:"100%"}}>          
          <Typography id="modal-modal-description" component="h2" sx={{marginBottom:"0.5em" }}>
              Ingrese el mes y el año del reporte que desea realizar
          </Typography> 
        <Box sx={{ display: 'flex', width: "100%", justifyContent:"space-around",gap: '1em' }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="month-select-label" sx={{ color: 'black' }}>Mes</InputLabel>
          <Select
            labelId="month-select-label"
            value={selectedMonth}
            label="Mes"
            onChange={handleMonthChange}
          >
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="year-select-label" sx={{ color: 'black' }}>Año</InputLabel>
          <Select
            labelId="year-select-label"
            value={selectedYear}
            label="Año"
            onChange={handleYearChange}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>           
      </Box>
      
        <Box sx={{ display: 'flex', width: "100%", justifyContent:"space-around", marginTop:"1em",gap: '1em' }}>
          <Button
            variant="contained"
            onClick={handleCancel}
            sx={{ 
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
          >
            Cancelar
          </Button>
          <Button        
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
          sx={{ 
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
      >Descargar
        <FileDownloadIcon aria-label="Download" color="primary" fontSize='small'/>
      </Button>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >        
        <MenuItem onClick={() => handleDownload("XSLX")}>
          <ListItemText>Excel</ListItemText>
          <DescriptionIcon color="success"></DescriptionIcon>
        </MenuItem>
        <MenuItem onClick={() => handleDownload("PDF")}>
          <ListItemText>PDF</ListItemText>
          <PictureAsPdfIcon color="error" aria-label="PDF" ></PictureAsPdfIcon>
        </MenuItem>        
      </StyledMenu>
          </Box>        
        </Grid>
        }       
      </ ModalComponent>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default BuscarDependenciaReporte;
